import React from 'react'
import './Home.css'


function Home() {
  return (
    <>
      <main className='mainContainer'>
        <div className="container">
          <h2>Impression Studio</h2>
          <p>Photography studio in Navi Mumbai, Maharashtra
          </p>
          <div className="gallery">
            <div className="box row-2" id='box1'></div>
            <div className="box col-2" id='box2'></div>
            <div className="box" id='box3'></div>
            <div className="box row-2" id='box4'></div>
            <div className="box col-2" id='box5'></div>
            <div className="box" id='box6'></div>
            <div className="box col-2" id='box7'></div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Home
